@import "../../../../../main/styles/variables";

.grade-card-image {
    display: flex;
    //grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 232px;
    
    border-radius: 12px;

    & > img{
        object-fit: contain;
        max-height: 100%;
    }

    @media all and (min-width: $media-tablet) {
        // width: 224px;
        // height: 224px;
    }

    @media all and (max-width: $media-tablet) {
        // min-width: 96px;
        min-height: 400px;

        & > img{
            width: 70px;
        }
    }
}