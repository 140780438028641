
.profile-course-list {
    --gap: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    // max-height: 272px;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    &__header {
        color: #00000052;
    }

    &__title {
        color: #234642;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 4px;
    }

    &__date {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: rgba(0, 0, 0, 0.32);
    }

    &__item {
        padding-bottom: var(--gap);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }

    &__show-more-button {
        --color: #234642;
        align-self: start;
        background: none;
        display: inline-flex;
        align-content: center;
        font-size: 16px;
        gap: 16px;
        color: var(--color);

        & span {
            display: inline-block;
            height: 20px;
            width: 10px;
            rotate: 90deg;

            & svg path {
                fill: var(--color);
            }
        }

        &[data-shown="true"] {
            span {
                transform: rotate(180deg);
            }
        }
    }
}