@import "../../../main/styles/variables";

.auth-layout {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: rgb(233,241,243);
    background: linear-gradient(180deg, rgba(233,241,243,1) 0%, rgba(234,242,244,1) 30%, rgb(237, 245, 247,1) 56%, rgba(246,252,255,255) 80%, rgba(238,245,251,255) 100%);
    min-height: 100vh;
    height: 100%;
    &__background {
        position: absolute;
        max-height: 100vh;
    }
    &__cubes {
        position: relative;
    }

    &__form {
        display: flex;
        align-items: center;
        div {
            background-color: white;
            z-index: 9999;
        }
    }

    &__brown-cube {
        position: absolute;
        left: 0;
        top: 192px;
    }

    &__green-cube {
        position: absolute;
        bottom: 0;
        left: 400px;
    }

    @media all and (max-width: $media-laptop-small) {

        grid-template-columns: 1fr;

        &__form {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 0 24px;
        }

        &__brown-cube { 
            top: 0px;
            width: 128px;
        }

        &__green-cube {
            bottom: -37px;
            width: 160px;
            right: 24px;
            left: unset;
        }
    }

    @media all and (max-width: $media-tablet){
        &__background{
            display: none;
        }
        &__cubes{
            display: none;
        }
        &__form > div{
            border-color: rgba(0, 0, 0, 0.2);
        }
        & {
            background: white;
        }

    }
    @media all and (min-width: $media-tablet) {
        &__brown-cube,&__green-cube {
            & > path {
                fill: white;
            }
        }

    }
}