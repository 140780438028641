
.dropdown {

    transition: all 3s linear;

    &__checkbox {
        display: none;
    }

    &__label {
        font-size: 20px;
        font-weight: bold;
        padding: 18px 32px;
        width: 100%;
    }
    
    &__content{
        max-height: 0px;
        overflow: hidden;
        opacity: 0;
        transition: all .3s linear;
    }
    
    &__checkbox:checked ~ &__content {
        max-height: 200px;
        opacity: 1;
    }

}