@import "../../../main/styles/variables";

.login-form {
    width: 480px;
    padding: 32px;

    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 16px;

    &__info {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-top: 48px;
        padding: 16px;
        border: 1px solid #E7BC6E;
        border-radius: 8px;

    }

    &__info-desc {
        padding-left: 16px;
        color: #000000;
        position: relative;

        &::before {
            content: "";
            width: 1px;
            height: 100%;
            background-color: #E7BC6E;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__link {
        font-weight: 700;
        color: #335D5C;
    }

    &__button-list {
        display: flex;
        gap: 8px;
        margin-top: 16px;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 16px;
    }

    &__about {
        display: flex;
        justify-content: space-between;

        margin-top: 48px;
    }

    &__copyright {
        color: #000000A3;
    }

    @media all and (max-width: $media-laptop-small) {
        width: unset;
        background-color: #FFFFFF52;

        &__info-desc {
            min-width: 140px;
        }

        &__button-list {
            flex-wrap: wrap;
            gap: 16px;
            margin-top: 24px;
        }

        &__button {
            justify-content: center;
        }
    }

    @media all and (max-width: $media-phone-medium) {
        &__info-desc {
            min-width: 0px;
        }
    }
}