
.profile-card-next-grade {
    padding: 24px;

    &__header {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.32);
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 16px;
    }

}