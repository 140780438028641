@import "../../../../../main/styles/variables";

.grade-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: max-content;
    
    &__signature {
        color: rgba(0, 0, 0, 0.32);
        font-weight: 600;
        font-size: 16px;
    }

    &__title {
        color: #234642;
        font-size: 24px;
        font-weight: 700;
        margin: 4px 0 8px 0;
    }
    & .slider-wrapper{
        max-width: 310px;
    }
    &__image {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 224px;
        height: 224px;
        background: rgba(231, 188, 110, 0.32);
        border-radius: 12px;
    }
    & .carousel.carousel-slider {
        overflow: unset !important;
    }
    & .carousel .control-dots{
        bottom: -30px;
    }
    & .dot{
        background-color: rgb(35 70 66) !important;
        width: 12px !important;
        height: 12px !important;
}

    @media all and (min-width: $media-tablet) {
        max-height: 352px;
    }

    @media all and (max-width: $media-tablet) {

        padding: 22px 16px !important;
        width: 100%;

        &__title {
            font-size: 20px;
        }
    }
}