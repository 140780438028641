
.privacy-details {

    &__title {
        margin-bottom: 1rem;
        font-size: 1.4rem;
        line-height: 1.375rem;
    }

    &__content {
        font-size: 1.2rem;
        line-height: 1.4;
        margin-bottom: 1rem;
    }
}