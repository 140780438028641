
.profile-course-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    min-width: 300px;
    min-height: 360px;

    &__bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__title {
        color: #234642;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
    }

    & img {
        flex: 1;
        object-fit: cover;
        max-width: 100%;
    }

    &__award-message {
        color: #234642;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        margin-block-end: auto;
    }
}