@import "../../../../../main/styles/variables";

.goals-card {

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.32);
    }

    &__list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__item {
        position: relative;
        padding: 0 12px 0 48px;
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        
        &::before {
            position: absolute;
            content: "";
            left: 14px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            width: 18px;
            height: 18px;
            border: 1px solid #000000;
            border-radius: 4px;
        }
    }

    @media all and (max-width: $media-tablet) {
        
        padding: 24px !important;
    }
}