.List{
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__item{
    position: relative;
    padding: 0 12px 0 48px;
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;

    &::before {
      position: absolute;
      content: "";
      left: 14px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 18px;
      height: 18px;
      border: 1px solid #000000;
      border-radius: 4px;
    }
  }
}