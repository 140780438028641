
.progress {

    &__progress {
        height: 4px;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;
        &::-webkit-progress-bar {
            background: rgba(51, 93, 92, 0.08);
        }
        &::-webkit-progress-value {
            background-color: #335D5C;
        }
    }

    &__bold {
        font-weight: 700;
    }

    &__values {
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        margin-top: 4px;
        color: #234642;
    }

}