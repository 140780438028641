.modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 4;

    &__background {
        height: 100%;
        width: 100%;
        background-color: rgba(128, 128, 128, 0.401);
    }

    &__container {
        background-color: #fff;
        // width: 90vw;
         height: 90vh;
        //min-width: 70vw;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        border-radius: 20px;
        padding: 40px;

        @media all and (max-width: 768px) {
            width: 80vw;
            padding: 10px;
            border-radius: 0;
        }
    }
}