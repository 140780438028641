@font-face {
    font-family: "Akrobat";
    src: url("../../presentation/assets/fonts/Akrobat-Regular.woff2") format("woff2");
    font-weight: 400;
}

@font-face {
    font-family: "Akrobat";
    src: url("../../presentation/assets/fonts/Akrobat-SemiBold.woff2") format("woff2");
    font-weight: 600;
}

@font-face {
    font-family: "Akrobat";
    src: url("../../presentation/assets/fonts/Akrobat-Bold.woff2") format("woff2");
    font-weight: 700;
}
