:root {
    --color-ui-base: #335D5C;
    --color-ui-brown: #E7BC6E;


}

// points for media queries
$media-phone-small: 320px;
$media-phone-medium: 375px;
$media-phone-large: 425px;

$media-tablet-small: 560px;
$media-tablet: 768px;

$media-laptop-small: 1024px;
$media-laptop-large: 1440px;