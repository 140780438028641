.base-layout {
    display: grid;
    grid-template-rows: min-content 1fr;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    
    &__content {
        margin: 66px auto 0 auto;
        max-width: 70%;
        min-width: 1024px;
        padding: 2rem;
    }
    &__info {
        display: flex;
        gap: 30px;
        margin-bottom: 30px;
    }
    &__info_text{
        width: 50%;
        color: #234642;
        font-size: 25px;
        font-weight: 700;
    }
    &__info_list{
        width: 50%;
        & > h4{
            font-size: 25px;
        }
    }

    @media (max-width: 767px) {
        .base-layout__content {
            max-width: 100%;
            min-width: 100%;
            margin: 0;
            margin-top: 66px;
            padding: 1rem;
        }
        &__info{
            flex-direction: column;
            & > div:first-child{
                font-size: 20px;
            }
            & > div {
                width: 100%;
            }
        }
    }

    @media (max-width: 1024px) {
        .base-layout__content {
            max-width: 100%;
            min-width: 100%;
            margin: 0;
            margin-top: 66px;
            padding: 1rem;
        }
        &__info_text{
            font-size: 20px;
        }
    }
}