.task-progress {
    position: relative;
    padding-left: 46px;
    
    &::before {
        border: 1px solid #000000;
        border-radius: 4px;
        height: 16px;
        width: 16px;
        position: absolute;
        content: "";
        top: 0;
        left: 16px;
        transform: rotate(45deg);
    }

    &_theme {
        &_white {
            color: #fff;
            &::before {
                outline: 1px solid #fff;
                border: none;
                width: 14px;
                height: 14px;
                outline-offset: 1px;
                background-color: #fff;
            }
            .progress__values {
                color: #fff;
            }
            .progress__progress {
                &::-webkit-progress-bar {
                    background: #818585;
                }
                &::-webkit-progress-value {
                    background-color: #fff;
                }
            }
        }
    }
}