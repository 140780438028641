.profile-course-card-level {
    cursor: pointer;
    position: relative;
    
    &:hover {
        .profile-course-card-level__info {
            display: block;
        }
    }

    &__info {
        display: none;
        position: absolute;
        z-index: 2;
        transform: translate(-50%, 100%);
        bottom: 0;
        left: 50%;
        background-color: #234642;
        padding: 16px;
        width: 100%;
        color: #FFFFFF;
        border-radius: 12px;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
    }

    &__list {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        max-height: 175px;
        overflow-y: auto;
        padding-top: 10px;
        gap: 12px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}