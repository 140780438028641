@import "../../../main/styles/variables";

.privacy-layout {
    margin: 0 10%;
    height: 100%;
    padding-top: 66px;
    min-height: 100vh;

    display: flex;
    justify-content: center;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 0 64px 64px 64px;
        margin-right: 200px;
        
        width: 800px;
        max-width: 100%;

        & > p {
            font-family: 'Akrobat';
            font-weight: 500;
            font-size: 16px;
            line-height: 180%;
            color: rgba(0, 0, 0, 0.88)
        }
    }

    @media all and (max-width: $media-laptop-small) {
        flex-direction: column-reverse;
        
        &__content{
            margin-right: 0;
        }
    }

    @media all and (max-width: $media-tablet) {
        margin: 0;

        &__content{
            padding: 0 32px;
        }
    }
}