
.gradation-card-modal {
  width: 100%;
  height: 100%;
 & div, & .slider {
     height: 100%;
 }
  & .slider {
    max-width: 500px;
  }
  &__image {
    max-width: 500px !important;
    height: 100%;
    object-fit: contain;
  }
  & .dot{
    background-color: rgb(35 70 66) !important;
    width: 12px !important;
    height: 12px !important;
  }
}