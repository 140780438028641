.carousel {
    position: relative;
    & > button {
        position: absolute;
        width: 48px;
        padding-right: 0px;
        padding-left: 0px;
        justify-content: center;
        top: 50%;
    }
    & > button:first-child{
        transform: rotate(180deg);
        left: -72px;
    }
    & > button:last-child{
        right: -72px;
    }
    &__wrapper{
        height: min-content;
        display: flex;
        gap: 16px;
        overflow-x: auto;
        padding-bottom: 10px;
        padding-top: 80px;
    }

    &__wrapper::-webkit-scrollbar {
        height: 8px;
        cursor: pointer;
    }
     
    &__wrapper::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #EFF2F2;
    }
     
    &__wrapper::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #234642;
    }
    @media all and (max-width: 1150px){
        & > button {
            display: none;
        }
    }

}
