@import "../../../main/styles/variables";

.profile {
    display: flex;
    gap: 12px;

    &__avatar {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        object-fit: cover;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__fullname {
        color: #000000;
        font-size: 16px;
        line-height: 100%;
    }

    &__link {
        color: #00000052;
    }

    @media all and (max-width: $media-tablet-small) {
        &__content{
            display: none;
        }
    }

}