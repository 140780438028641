
.redirect {
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 16px;
    transition: all 1s;

    &__header {
        overflow: hidden;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 6px;
        width: 100%;
        color: #335D5C;
        font-size: 16px;
        transition: all 1s;

        svg {
            filter: brightness(0) saturate(100%) invert(27%) sepia(41%) 
                    saturate(450%) hue-rotate(130deg) brightness(101%) contrast(85%);
        }
    }

    &:hover {
        border: 1px solid #335D5C;
        .redirect__title {
            transform: translateX(90%);
        }
    }

    > *:not(:first-child) {
        transition: all 0.3s;
    } 


    // themes

    &_theme {
        &_link {
            width: min-content;
            white-space: nowrap;
            border: none;
            padding: 0;
            color: #335D5C;
            text-decoration-line: underline;
            .redirect__header {
                display: none;
            }
            &:hover {
                border: none;
                .redirect__title {
                    transform: none;
                }
            }
        }
    }
}