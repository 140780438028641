.level {
    width: 64px;
    height: 64px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    &__block {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E7BC6E;
        font-size: 20px;
        font-weight: 900;

        width: 26px;
        height: 26px;
        background-color: #234642;
        border-radius: 8px;
        top: 30.5%;
        left: 30%;
        position: absolute;
    }
}