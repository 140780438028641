
.notification-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
    
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        border-right: 1px solid #E7BC6E;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__title {
        color: #000000;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;

        span {
            color: #335D5C;
            font-weight: 700;
        }
    }

    &__decs {
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
    }

    &__date-time {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: rgba(0, 0, 0, 0.32);
    }

    &_viewed {
        opacity: 0.4;
    }
}