@import "../../../main/styles/variables";
.header {
    display: flex;
    font-size: 1em;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    height: 66px;
    background: rgba(255, 255, 255, 0.32);
    backdrop-filter: blur(4px);
    position: fixed;
    width: 100%;
    z-index: 2;

    &__logo-container {
        display: flex;
        gap: 2rem;
    }
    
    &__logo {
        cursor: pointer;
        height: 40px;

        img {
            display: block;
            height: 100%;
        }
    }

    &__panel {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    @media all and (max-width: $media-phone-medium) {
        &__logo-container {
            gap: 1rem;
        }
        &__panel {
            gap: 12px;
        }
    }

    @media all and (max-width: $media-tablet-small) {
        &__logo {
            height: 30px;
        }
    }
}
