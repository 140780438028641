@import "../../../main/styles/variables";

.privacy-menu {
    width: 192px;
    user-select: none;
    position: sticky;
    top: 66px;
    height: max-content;
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 24px;

    &__checkbox {
        display: none;
    }

    &__label {
        font-size: 20px;
        font-weight: bold;
        display: none;
    }

    &__list {
        flex-direction: column;
        gap: 1.8rem;
        display: flex;
    }

    &__link {
        display: block;
        width: 100%;
        font-size: 16px;
        position: relative;
        color: #000000A3;
        
        &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: -8px;
            width: 100%;
            height: 1px;
            background-color: #E0E0E0;
        }

        svg {
            margin-top: 2px;
            filter: brightness(0) saturate(100%);
            opacity: 0.64;
        }
        &_active {
            color: #000000;
            font-weight: 600;
            svg {
                opacity: 1;
            }
            &::after {
                background-color: #335D5C;
            }
        }
    }

    @media all and (max-width: $media-tablet) {
    }

    @media all and (max-width: $media-laptop-small) {
        width: 100%;
        padding: 16px 35px;

        &__label::after{
            content: "";
            width: 16px;
            height: 16px;
            border-right: 1px solid black;
            border-bottom: 1px solid black;
            position: absolute;
            right: 0px;
            top: calc(50% - 4px);
            transform: rotate(-135deg);
            cursor: pointer;
        }

        &__list {
            max-height: 0;
            opacity: 0;
            padding-top: 0px;
            visibility: hidden;
            transition: .3s linear;
            display: none;
        }

        &__label {
            display: block;
            position: relative;
        }

        &__checkbox:checked ~ &__list{
            overflow: hidden;
            max-height: 400px;
            padding-top: 18px;
            opacity: 1;
            visibility: visible;
            display: flex;
        }

        &__checkbox:checked ~ &__label::after{
            transform: rotate(45deg);
        }
    }

}