@import "../../../../../main/styles/variables";

.profile-my-grade {
    margin: 32px 0;
    margin-bottom: -48px;

    &__header {
        font-weight: 600;
        font-size: 32px;
        line-height: 100%;
        color: #335D5C;
    }

    &__top {
        margin-top: 16px;

        display: grid;
        grid-template-columns: 3fr 2fr;
        gap: 8px;
    }

    &__bottom {
        margin-top: 8px;

        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 8px;
    }

    &__level, &__card {
        padding: 24px;
    }

    &__card {
        .profile-my-grade__sub-title {
            max-width: 100px;
            margin-bottom: 4px;
        }
    }

    &__right{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
    }

    &__level {
        display: flex;
        gap: 16px;
    }

    &__my-level {
        display: flex;
        justify-content: center;
        gap: 4px;
        flex-direction: column;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #234642;
    }

    &__sub-title {
        color: #00000052;
        font-weight: 500;
        line-height: 100%;
    }
    
    @media all and (max-width: $media-tablet) {
        &__top {
            grid-template-columns: 1fr;
        }

        &__bottom {
            grid-template-columns: 1fr;
        }
    }
}