@import "../../../../../main/styles/variables";

.profile-header {
    display: grid;
    grid-template-columns: 256px 1fr;
    column-gap: 16px;

    
    &__date, &__location, &__bottom {
        padding: 24px;
    }

    &__header {
        grid-area: header;
    }

    &__bottom {
        display: grid;
        grid-template-columns: min-content 1fr;
        gap: 16px;
        grid-area: id;
    }

    &__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__exit {
        justify-content: center;
        svg {
            filter: brightness(0) saturate(100%) invert(23%) sepia(40%) saturate(433%) hue-rotate(124deg) brightness(90%) contrast(94%);
        }
    }

    &__avatar {
        width: 100%;
        height: 256px;
        object-fit: cover;
        border-radius: 16px;
    }

    &__fullname {
        color: #000000;
        font-weight: 700;
        font-size: 48px;
        line-height: 100%;
        margin-bottom: 16px;
        max-width: 400px;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: #234642;
    }

    &__sub-title, &__birthdate {
        line-height: 100%;
    }

    &__sub-title {
        width: max-content;
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
    }

    &__birthdate {
        margin-top: 4px;
        font-weight: 500;
        font-size: 20px;
    }

    &__characteristic {
        display: grid;
        gap: 8px;
        grid-template-areas: 
            "date location"
            "id id"
        ;
        grid-template-columns: 1fr 5fr;
    }


    &__date {
        display: grid;
        justify-content: center;
        text-align: center;
        grid-area: date;

        & > {
            .profile-header__title {
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                padding-bottom: 8px;
            }
            .profile-header__sub-title {
                margin-top: 8px;
            }
        }
    }
    
    &__location {
        grid-area: location;
    }

    &__logo {
        padding: 7px 16px 7px 0;
        border-right: 1px solid #E7BC6E;
        padding-right: 16px;
    }

    &__button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
            filter: brightness(0) saturate(100%) invert(21%) sepia(21%) saturate(1141%) hue-rotate(124deg) brightness(85%) contrast(82%);
        }
    }

    &__information {
        display: flex;
        gap: 16px;
    }

    &__info-left {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    @media all and (max-width: $media-tablet) {
        grid-template-columns: 1fr;

        &__left{
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 32px;
        }

        &__avatar{
            width: 192px;
            height: 192px;
        }

        &__exit{
            width: 48px;
            height: 48px;
            padding: 0;
        }

        &__bottom{
            grid-template-columns: 1fr;
        }

        &__button-next{
            justify-content: center;
        }

        &__title{
            font-size: 16px;
        }

        &__age{
            font-size: 24px;
        }
    }
}