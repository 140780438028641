
.button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    border-radius: 8px;
    padding: 16px 24px;
    width: 100%;
    text-align: left;
    background-color: #335D5C;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: #FFFFFF;

    &:hover {
        outline: 1px solid #335D5C;
    }

    svg {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) 
                saturate(7496%) hue-rotate(274deg) brightness(102%) contrast(99%);
    }

    &_theme {
        
        &_outline {
            background-color: inherit;
            color: #000000;
            font-weight: 400;
            border: 1px solid #000000;
            svg {
                filter: brightness(0) saturate(100%)            
            }
            &:hover {
                outline: 1px solid #000000;
            }
        }

        &_gray {
            color: #234642;
            background: rgba(51, 93, 92, 0.04);
        }
    }

    &_size {
        &_small {
            width: min-content;
        }
    }
}