
.card {
    padding: 32px;
    border-radius: 16px;
    border-style: solid;
    border-width: 1px;
    border-color: #00000014;
    
    &_border {
        &_brown {
            border-width: 2px;
            border-color: #E7BC6E;
        }
    }
}