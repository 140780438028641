@import "../../../main/styles/variables";

.gradation-card {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 32px;

    &__title {
        color: #234642;
        font-size: 48px;
        line-height: 100%;
        font-weight: 600;
    }

    &__left {
        border-right: 1px solid #FBF4E8;
    }

    &__level-icon {
        width: 192px;
        height: 192px;
        position: relative;
        img {
            width: 192px;
            height: 192px;
        }
    }

    &__level-icon_mobile {
        display: none;        
    }

    &__level {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        width: 48px;
        height: 48px;
        font-size: 32px;
        top: 37.5%;
        left: 37.5%;
        padding-top: 2px;
        color: #E7BC6E;
        font-weight: 900;
        background-color: #234642;
        position: absolute;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding-bottom: 64px;
    }

    &__requirements {
        display: grid;
        grid-template-columns: 300px 1fr;
        gap: 16px;
    }

    @media all and (max-width: $media-laptop-small) {
        grid-template-columns: 1fr;

        &__left {
            display: none;
        }
    }

    @media all and (max-width: $media-tablet) {

        &__requirements {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
        }

        &__title {
            font-size: 32px;
        }

        &__level-icon_mobile {
            display: block;
            width: 64px;
            height: 64px;
            position: relative;

            & > img{
                width: 100%;
                height: unset;
            }

            &::before {
                content: "";
                position: absolute;
                height: 100%;
                width: 1px;
                right: -24px;
                background-color: var(--color-ui-brown);
                opacity: .8;
            }
        }

        &__level {
            width: 20px;
            height: 20px;
            font-size: 16px;
            border-radius: 8px;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
        }

        &__header {
            display: grid;
            grid-template-columns: min-content 1fr;
            align-items: center;
            gap: 48px;
        }
    }
}