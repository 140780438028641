@import "../../../main/styles/variables";

.notification {
    position: relative;

    &__checkbox {
        display: none;
        &:checked  ~ .notification__list{
            opacity: 1;
            height: 360px;
        }
        &:checked ~ .notification__label {
            outline: 1px solid #335D5C;
        }
        &:checked ~ .notification__closed {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: center;

        user-select: none;
        cursor: pointer;
        padding: 12px 14px;
        border-radius: 12px;
        position: relative;

        &[data-unread='true'] {
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                background-color: #E24E1B;
                border-radius: 50%;
                bottom: 14px;
                right: 12px;
            }
        }

        &:hover {
            background: rgba(51, 93, 92, 0.04);
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        z-index: 1;

        overflow: auto;

        padding: 28px;
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.5s;
        position: absolute;
        bottom: -16px;
        right: 16px;
        height: 0;
        width: 512px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        background: #FFFFFF;

        &::-webkit-scrollbar {
            display: none;
        }

        &__message {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-size: 20px;
        }
    }

    @media all and (max-width: $media-tablet) {
        &__list{
            position: fixed;
            left: 0;
            width: 100%;
            border: none;
            transform: none;
            bottom: unset;
            top: 0;
            z-index: -1;
            // height: calc(100vh - 66px) !important;
        }

        &__checkbox {
            &:checked  ~ .notification__list{
                z-index: 100;
                top: 67px;
                opacity: 1;
                height: calc(100vh - 66px) !important;
            }
        }
        
    }
}