@import "../../../../../main/styles/variables";

.profile-course-card-item {
    display: flex;
    width: 100%;
    gap: 8px;
    padding: 8px;
    border: 1px solid #E7BC6E;
    border-radius: 12px;
    position: relative;
    cursor: pointer;
    min-height: 55px;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &__sub-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 100%;
        color: rgba(0, 0, 0, 0.32);
    }

    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #234642;
    }

    &__avatar {
        max-width: 48px;
        max-height: 48px;

        img {
            width: 34px;
            height: 34px;
        }
    }

    & > div.active:not(:nth-child(2)){
        opacity: 0;
        display: none;
        transition: all 0.3s;
    }

    &__hover.active {
        display: flex;
        animation-duration: 0.5s;
        animation-name: CardHover;
        animation-fill-mode: forwards;
    }


    &__hover {
        display: none;
        opacity: 0;
        flex-direction: column;
        gap: 4px;
        padding: 20px;
        background-color: #234642;
        border-radius: 12px;
        height: 0px;
        overflow: hidden;
        width: 100%;
        bottom: 0;
        z-index: 100;

        .profile-course-card-item__sub-title {
            color: #ffffff;
        }

        .profile-course-card-item__title {
            color: #ffffff;
        }
    }

    &__hover-image {
        width: 100%;
        height: 224px;
        background-color: #ffffff;
        margin-bottom: 4px;
        display: flex;
    }

    @media all and (max-width: $media-tablet) {
        &__avatar > img {
            width: 100%;
            height: unset;
            padding: 10px;
        }
    }
}


@keyframes CardHover {
    0%{
        display: none;
        opacity: 0;
        height: 0px;
    }
    25% {
        display: flex;
        opacity: 0;
        height: 0;
    }
    50% {
        display: flex;
        opacity: 0;
        height: 300px;
    }
    75%{
        display: flex;
        opacity: 1;
        height: 300px;
    }
    100%{
        display: flex;
        height: 300px;
        opacity: 1;

    }
}